import {  useController } from "react-hook-form";
import { BaseFormProps } from "./types";
import { getFieldErrMsg } from "utils/handle-api-error";

interface TextFieldProps extends BaseFormProps {
    isNumeric?: boolean,
    handleEnter?: (e: any) => void
    regex?: 'email' | 'phoneNumber'
    transformUppercase?: boolean
    removeSpaces?: boolean
    minDate?: string;
    validateEndDate?: boolean;
    startDate?: string;
    isRequired?: boolean;
}

export default function TextField({
    fieldName, control, isNumeric, handleEnter,
    label, desc, className, errorCfg, regex, transformUppercase = false, max, min, removeSpaces = false, minDate,
    validateEndDate,
    startDate, isRequired,  ...rest
}: TextFieldProps) {
    let classes = 'form-group' + (className ? ' ' + className : '')
    classes += rest.required ? ' required' : ''
    if (handleEnter) {
        rest.onKeyUp = (e: any) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
                handleEnter(e)
            }
        }
    }
    let pattern;
    switch (regex) {
        case 'email':
            pattern = {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Please enter a valid email',
            }
            break
    }
    const { field, fieldState } = useController({
        name: fieldName, control, rules: {                      
            required: `${isRequired ? "Please fill out this field" : ""}`  || false,
            pattern: pattern,
            min: {
                value: +min,
                message: `Minimum input is ${min}`
            },
            validate: validateEndDate ? {
                isAfterStart: (value) => 
                    !value || !startDate || new Date(value) >= new Date(startDate) ||
                    "End date must be after the start date"
            } : undefined
        }
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let text = event.target.value
        if (removeSpaces === true) {
            text = text.replace(' ', '')
        }
        if (transformUppercase) {
            text = transformUppercase ? text.toUpperCase() : text;
        }
        if (max && max < +text) {
            text = max
            return
        }
        if (max && text.length > +max) {
            text = text.substring(0, +max); 
        }
        if (min != undefined && min > +text) {
            text = min
            return
        }
        field.onChange(text)
    };


    return (
        <span className={classes}>
            {label ? <label className='form-label'>{label}</label> : ''}
            <input className={`form-control ${ fieldState.error ? "border-danger": "border-[#EAECF0]"}`} onChange={handleChange}
                onBlur={(e: any) => {
                    let value: string = e.target.value
                    if (isNumeric) {
                        if (isNaN(parseFloat(value.toString()))) value = ''
                    }
                    field.onChange(value)
                }}
                name={field.name} value={field.value || ''} ref={field.ref}
                min={minDate || startDate}
                {...rest}
                onWheel={isNumeric ? (e) => { e.preventDefault() } : undefined}
                type={isNumeric ? 'number' : (rest.type || 'text')}
            />
            <span className="flex flex-col">
            {!desc || (fieldState.error) ? null : (
                    <span className="desc">{desc}</span>
                )}
                {
                    !fieldState.error || (errorCfg && errorCfg.hideErrMsg) ? <></> :
                        <span className="err-msg">
                            {getFieldErrMsg(
                                fieldState.error, fieldName, (errorCfg && errorCfg.errLabel ? errorCfg.errLabel : '')
                            )}
                        </span>
                }
            </span>
        </span>
    )
}