'use client';

import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import { KanbanDataProps } from "components/cards/KanbanCard";
import { getAllInvoices, getAllInvoiceHistory } from "api/invoice";
import { getItemRequests } from "api/item-requests";
import { getPriorityBookings } from "api/priority";
import { getRescheduleRequests } from "api/reschedule-requests";
import { getLateTechSchedules, getIncompleteTasks } from "api/service-schedules";
import { RequestObject } from "components/custom-modals/RequestModal";
import { isBefore, addHours } from "date-fns";
import { changeTz } from "utils/data-formatter";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage } from "utils/storageHelper";

interface ContextProps {
    lowPriorityRequests: KanbanDataProps[]
    mediumPriorityRequests: KanbanDataProps[]
    highPriorityRequests: KanbanDataProps[]
    setLowPriorityRequests: Dispatch<SetStateAction<KanbanDataProps[]>>
    setMediumPriorityRequests: Dispatch<SetStateAction<KanbanDataProps[]>>
    setHighPriorityRequests: Dispatch<SetStateAction<KanbanDataProps[]>>
    totalRequests: number
    refreshAll: () => void
}


const TaskBoardContext = createContext<ContextProps>({
    lowPriorityRequests: [],
    mediumPriorityRequests: [],
    highPriorityRequests: [],
    setLowPriorityRequests: (() => { }),
    setMediumPriorityRequests: (() => { }),
    setHighPriorityRequests: (() => { }),
    refreshAll: (() => { }),
    totalRequests: 0
})

export const TaskBoardContextProvider = ({ children }: { children: any }) => {
    const [lowPriorityRequests, setLowPriorityRequests] = useState<KanbanDataProps[]>([])
    const [mediumPriorityRequests, setMediumPriorityRequests] = useState<KanbanDataProps[]>([])
    const [highPriorityRequests, setHighPriorityRequests] = useState<KanbanDataProps[]>([])
    const totalRequests = lowPriorityRequests.length + mediumPriorityRequests.length + highPriorityRequests.length
    const user = getLocalStorage('user')
    const location = useLocation()
    const navigate = useNavigate()
    const { data: priorityBookings } = useSWR({ name: 'priority-bookings' }, () => user && !location.pathname.includes('/login') && getPriorityBookings())
    const { data: rescheduleRequests } = useSWR({ name: 'reschedule-requests' }, () => user && !location.pathname.includes('/login') && getRescheduleRequests())
    const { data: techLate } = useSWR({ name: 'tech-late' }, () => user && !location.pathname.includes('/login') && getLateTechSchedules())
    const { data: itemRequests } = useSWR({ name: 'item-requests' }, () => user && !location.pathname.includes('/login') && getItemRequests())
    const { data: incompleteTasks } = useSWR({ name: 'incomplete-tasks' }, () => user && !location.pathname.includes('/login') && getIncompleteTasks())
    const { data: invoices = [] } = useSWR({ item: 'invoices' }, () => user && !location.pathname.includes('/login') && getAllInvoices(true))
    const { data: technicianCarryCash = [] } = useSWR({ name: 'invoice-history', method: 'onsite', claimed: false, manual: true }, () => user && !location.pathname.includes('/login') && getAllInvoiceHistory('onsite', true, false))


    // High Priority Stuff
    useEffect(() => {
        let result: (KanbanDataProps & RequestObject)[] = []
        if (priorityBookings) {
            const pbFormatted: KanbanDataProps[] = priorityBookings.list.map((request: any): KanbanDataProps & RequestObject => {
                return {
                    id: request.id,
                    user: request.user,
                    date: new Date(request.pbScheduleInvoices[(request.pbScheduleInvoices.length - 1)].updatedAt),
                    orderId: request.order.id,
                    scheduleId: request.id,
                    subTitle: '',
                    timerMode: 'decrement',
                    topic: 'priority',
                    submitId: request.id,
                    showAction: true
                }
            })
            result = [...result, ...pbFormatted]
        }
        if (rescheduleRequests) {
            const rescheduleFormatted: KanbanDataProps[] = rescheduleRequests.list.map((request: any): KanbanDataProps & RequestObject => {
                return {
                    id: request.id,
                    showAction: true,
                    user: request.user,
                    team: request.technicianTeam,
                    techName: request.reschedulingTech && request.reschedulingTech.name,
                    date: new Date(request.updatedAt),
                    orderId: request.order.id,
                    scheduleId: request.id,
                    subTitle: request.rescheduleReason,
                    timerMode: "increment",
                    jobTypes: Array.from(new Set(request.tasks?.map((task: any) => JSON.stringify(({ name: task.item.service.jobType.name, colorHex: task.item.service.jobType.colorHex }))))),
                    topic: 'reschedule',
                    submitId: request.id,
                    reason: request.rescheduleReason,
                    requestBy: request.assignedTeam && request.assignedTeam.assignedTechnicians.map((at: any) => `${at.technician.name} ${at.role == 1 ? '(Leader)' : ''}`).join(', ')
                }
            })
            result = [...result, ...rescheduleFormatted]
        }

        if (itemRequests) {
            const itemsRequestFormatted: KanbanDataProps[] = itemRequests.filter((request: any) => request.scheduleTasks[0] != undefined).map((request: any): KanbanDataProps & RequestObject => {
                return {
                    id: request.id,
                    showAction: true,
                    user: request.order.user,
                    team: request.scheduleTasks[0].schedule && request.scheduleTasks[0].schedule.technicianTeam,
                    techName: request.addingTech && request.addingTech.name,
                    date: new Date(request.updatedAt),
                    orderId: request.order.id,
                    scheduleId: request.scheduleTasks[0].schedule && request.scheduleTasks[0].schedule.id,
                    subTitle: `Additional ${request.quantity} unit ${request.service.name} (${request.serviceCategory.name}) request`,
                    timerMode: 'increment',
                    jobTypes: [JSON.stringify({ name: request.service.jobType.name, colorHex: request.service.jobType.colorHex })],
                    topic: 'item-request',
                    submitId: request.id,
                    requestBy: request.assignedTeam && request.assignedTeam.assignedTechnicians.map((at: any) => `${at.technician.name} ${at.role == 1 ? '(Leader)' : ''}`).join(', ')
                }
            })
            result = [...result, ...itemsRequestFormatted]
        }

        if (techLate) {
            const techLateFormatted: KanbanDataProps[] = techLate.map((request: any) => {
                const technicians = request.assignedTeam && request.assignedTeam.assignedTechnicians.map((at: any) => `${at.technician.name} ${at.role == 1 ? '(Leader)' : ''}`).join(', ')
                return {
                    id: request.id,
                    user: request.user,
                    team: request.technicianTeam,
                    date: isBefore(new Date(), addHours(request.startDate, 1)) ? new Date(request.startDate) : addHours(request.startDate, 1),
                    orderId: request.order.id,
                    scheduleId: request.id,
                    subTitle: `Job is scheduled for ${changeTz(request.startDate, 'Asia/Singapore', 'HH:mm')}.\n ${technicians ? `Technicians are ${technicians}` : 'There are no technicians assigned.'}`,
                    timerMode: isBefore(new Date(), addHours(request.startDate, 1)) ? 'decrement' : 'increment',
                    topic: 'tech-late',
                    jobTypes: Array.from(new Set(request.tasks?.map((task: any) => JSON.stringify(({ name: task.item.service.jobType.name, colorHex: task.item.service.jobType.colorHex }))))),
                    showAction: false,
                    requestBy: technicians
                }
            })
            result = [...result, ...techLateFormatted]
        }
        setHighPriorityRequests(result.sort((a, b) => isBefore(a.date, b.date) ? 1 : -1))
    }, [priorityBookings, rescheduleRequests, itemRequests, techLate])

    // Medium Priority Stuff
    useEffect(() => {
        let result: (KanbanDataProps & RequestObject)[] = []
        if (invoices && invoices.list?.length > 0) {
            const invoicesPbFormatted: KanbanDataProps[] = invoices.list.map((invoice: any) => {
                let reason = '';
                if (invoice.customerDoesntWantToPay) {
                    reason = ". Customer doesn't want to pay."
                } else if (invoice.customerWantsToPayLater) {
                    reason = ". Customer wants to pay later."
                }
                return {
                    id: invoice.id,
                    user: invoice.user,
                    team: {},
                    date: new Date(invoice.createdAt),
                    orderId: invoice.order.id,
                    scheduleId: "",
                    subTitle: `Amount Due $ ${(invoice.amountDue || invoice.amount || 0).toFixed(2)}${reason}`,
                    topic: "pending-payment",
                    submitId: "",
                    jobTypes: Array.from(new Set(invoice.items.map((item: any) => JSON.stringify(({ name: item.service.jobType.name, colorHex: item.service.jobType.colorHex }))))),
                    accentColor: "#FC8800",
                    isCompleted: invoice.customerDoesntWantToPay || invoice.customerWantsToPayLater || invoice.order.completedAt != null,
                    onClick: () => navigate(`/service-orders/${invoice.order.id}?scroll=invoice-${invoice.id}`)
                }
            })
            result = [...result, ...invoicesPbFormatted]
        }

        if (result.length > 0 || (result.length == 0 && mediumPriorityRequests.length != 0))
            setMediumPriorityRequests(result)
    }, [invoices])

    // Low Priority Stuff
    useEffect(() => {
        let result: KanbanDataProps[] = []

        if (technicianCarryCash && technicianCarryCash.list) {
            technicianCarryCash.list.map((req: any) => {
                result.push({
                    id: "",
                    user: { name: req.createdBy, phone: req.createdBy.phoneNumber },
                    team: undefined,
                    date: req.createdAt,
                    orderId: req.invoice && req.invoice.order && req.invoice.order.id,
                    scheduleId: "",
                    subTitle: `$ ${req.amount.toFixed(2)}`,
                    jobTypes: Array.from(new Set(req.invoice.items.map((item: any) => JSON.stringify(({ name: item.service.jobType.name, colorHex: item.service.jobType.colorHex }))))),
                    topic: "technician-carry-cash",
                    submitId: "",
                    onClick: () => navigate(`/service-orders/${req.invoice.order.id}?scroll=invoice-${req.invoice.id}`)
                })
            })
        }

        if (incompleteTasks) {
            const incompleteTaskFormatted: KanbanDataProps[] = incompleteTasks.map((request: any) => {
                return {
                    id: request.id,
                    user: request.schedule?.user,
                    team: {
                        name: request.schedule.finishingTech?.name,
                        profilePhoto: request.schedule.finishingTech?.profilePicture
                    },
                    date: request.createdAt,
                    orderId: request.schedule.order?.id,
                    scheduleId: request.schedule.id,
                    subTitle: `${request.incompleteReason} \n\n ${request.item.quantity} ${request.item.service.serviceUnit.name}(s) ${request.item.service.name} (${request.item.serviceCategory.name})`,
                    topic: 'incomplete-task',
                    jobTypes: [JSON.stringify(({ name: request.item.service.jobType.name, colorHex: request.item.service.jobType.colorHex }))],
                    showAction: false,
                    onClick: () => navigate(`/service-orders/${request.schedule.order?.id}?scroll=incomplete-task-${request.id}`)
                }
            })
            result = [...incompleteTaskFormatted, ...result]
        }

        if (result.length > 0 || (result.length == 0 && lowPriorityRequests.length != 0))
            setLowPriorityRequests(result)
    }, [technicianCarryCash])

    const refreshAll = () => {
        mutate({ name: 'priority-bookings' })
        mutate({ name: 'reschedule-requests' })
        mutate({ name: 'tech-late' })
        mutate({ name: 'item-requests' })
        mutate({ name: 'incomplete-tasks' })
        mutate({ name: 'invoices' })
        mutate({ name: 'invoice-history', method: 'onsite', claimed: false, manual: true })
    }



    return (
        <TaskBoardContext.Provider value={{ lowPriorityRequests, setLowPriorityRequests, mediumPriorityRequests, setMediumPriorityRequests, highPriorityRequests, setHighPriorityRequests, totalRequests, refreshAll }}>{children}</TaskBoardContext.Provider>
    )
};

export const useTaskBoard = () => useContext(TaskBoardContext);




