import { MetaQuery, ResourceProps } from "@refinedev/core";

import { TECHNICIANS_CONFIG } from "resources-config/technicians-config";
import { ADMINS_CONFIG } from "resources-config/admins-config";
import { ZONES_CONFIG } from "resources-config/zones-config";
import { TECHNICIAN_TEAMS_CONFIG } from "./technician-teams-config";
import { USERS_CONFIG } from "./users-config";
import { SERVICES_CONFIG } from "./services-config";
import { SERVICE_CATEGORIES_CONFIG } from "./service-categories-config";
import { SERVICE_UNITS_CONFIG } from "./service-units-config";
import { SERVICE_JOB_TYPES_CONFIG } from "./service-job-types-config";
import { SERVICE_SCHEDULES_CONFIG } from "./service-schedule/service-schedule-config";
import { PUBLIC_HOLIDAYS_CONFIG } from "./public-holiday-config";
import { SERVICE_ORDER_CONFIG } from "./service-order/service-order-config";
import { AIRCON_BRAND_CONFIG } from "./aircon/aircon-brand-config";
import { AIRCON_MODEL_CONFIG } from "./aircon/aircon-model-config";
import { DAILY_SCHEDULES_CONFIG } from "./service-schedule/daily-schedule-config";
import { DASHBOARD_CONFIG } from "./dashboard-config";
import { PROMO_CONFIG } from "./promo-config";
import { PENDING_PAYMENT_CONFIG } from "./pending-payment-config";
import { TECHNICIAN_CARRY_CASH_CONFIG } from "./technician-carry-cash-config";
import { QUOTATION_CONFIG } from "./quotation/quotation-config";
import { INVOICES_CONFIG } from "./quotation/invoices-config";
import { LEAD_SOURCES_CONFIG } from "./lead-sources-config";
import { QUOTATION_ITEMS_CONFIG } from "./quotation/quotation-items-config";
import { PROMO_CODE_USAGE_CONFIG } from "./promo-code-usage-config";

export class RESOURCE_CONFIG {
    static DATA = [
        DASHBOARD_CONFIG,
        PENDING_PAYMENT_CONFIG,
        TECHNICIAN_CARRY_CASH_CONFIG,
        USERS_CONFIG,
        SERVICES_CONFIG, SERVICE_CATEGORIES_CONFIG, SERVICE_UNITS_CONFIG,
        QUOTATION_CONFIG, QUOTATION_ITEMS_CONFIG, INVOICES_CONFIG,
        SERVICE_ORDER_CONFIG,
        SERVICE_SCHEDULES_CONFIG, DAILY_SCHEDULES_CONFIG,
        // PUBLIC_HOLIDAYS_CONFIG,
        TECHNICIANS_CONFIG, TECHNICIAN_TEAMS_CONFIG, ZONES_CONFIG,
        SERVICE_JOB_TYPES_CONFIG,
        PROMO_CONFIG, PROMO_CODE_USAGE_CONFIG,
        ADMINS_CONFIG,
        AIRCON_BRAND_CONFIG, AIRCON_MODEL_CONFIG,
        LEAD_SOURCES_CONFIG
        // .. Add another config hereX
    ]

    static getResources = (): ResourceProps[] => this.DATA.map(data => {
        const actions = data.actions || {}
        const resource: any = { ...data }
        delete resource.actions
        for (const actionName in actions) {
            const action = actions[actionName as keyof ResourceAction]
            if (action && action.path) {
                resource[actionName] = action.path
            }
        }
        return resource
    })

    static getPermissions = () => {
        const permissions: { [key: string]: { [roleName: string]: string[] } } = {}
        this.DATA.forEach(data => {
            const actions = data.actions || {}
            const resourceName = data.name
            permissions[resourceName] = {}
            for (const actionName in actions) {
                const action = actions[actionName as keyof ResourceAction]
                const allowedRoles = action?.allowedRoles || []
                allowedRoles.forEach(role => {
                    if (Array.isArray(permissions[resourceName][role])) {
                        permissions[resourceName][role].push(actionName)
                    }
                    else {
                        permissions[resourceName][role] = [actionName]
                    }
                });
            }
        })
        return permissions
    }

    static getRoutes = () => {
        type RouteType = {
            name: string,
            path: string,
            action: string,
            page: any
        }
        let routes: RouteType[] = []
        this.DATA.forEach(data => {
            const resourceName = data.name
            for (const actionName in data.actions) {
                const action = data.actions[actionName as keyof ResourceAction]
                if (action && action.path && action.page) {
                    const route: RouteType = {
                        name: resourceName, action: actionName, path: action.path,
                        page: action.page
                    }
                    routes.push(route)
                }
            }

        })
        return routes
    }

}

type ResourceActionData = {
    path?: string
    page?: any
    allowedRoles: string[]

}
type ResourceAction = {
    list?: ResourceActionData
    show?: ResourceActionData
    create?: ResourceActionData
    edit?: ResourceActionData
}

export interface MetaApiEndpointsType {
    getList?: string
    create?: string
    update?: string
    deleteOne?: string
    getOne?: string
}

export interface ResourceMetaType extends MetaQuery {
    parent?: string,
    canDelete?: boolean,
    label?: string,
    labelAsChild?: string,
    icon?: JSX.Element,
    apiEndpoints?: MetaApiEndpointsType,
}

export type ResourceType = {
    name: string,
    label?: string,
    meta?: ResourceMetaType
    actions?: ResourceAction
}