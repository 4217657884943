import { useCallback } from 'react'
import { useTable, CanAccess, BaseKey, useModal, useModalReturnType } from "@refinedev/core"
import { USERS_CONFIG } from 'resources-config/users-config'
import { TextField } from 'components/forms'
import { TAction, TActionItem, TBody, THead, Table, Td, Th } from "components/Table"
import Pagination from 'components/Pagination'
import PageTitle from "components/PageTitle"
import { baseApi } from 'providers/customDataProvider'
import { BaseCard } from 'components/cards'
import { Button } from 'components/buttons'
import { NavLink } from 'react-router-dom'
import Badge from 'components/Badge'
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from 'components/TableWidgets'
import useTableQuery from 'utils/useTableQuery'
import Pill from 'components/Pill'
import { formatFullDate } from 'utils/date'
import { toast } from 'sonner'
import { formatAddressAny } from 'utils/address'

export default function ListUser() {
    const filterModal = useModal()
    const { tableQueryResult, setFilters, pageSize, current, setCurrent, setPageSize, filters, setSorters, sorters } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 20 },
        resource: USERS_CONFIG.name,
    })
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    const toggleActiveUser = useCallback(async (id: BaseKey, isActive: boolean) => {
        try {
            await baseApi.post('admin/users/toggle-active/' + id, { isActive: isActive })
            tableQueryResult.refetch()
        } catch (error: any) {
            toast.error(error.message)
            console.log(error)
        }
    }, [tableQueryResult.refetch])

    const { control, doFilter } = useTableQuery(filters, setFilters)

    return (<>
        <PageTitle title='Customers' />
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft>
                    <h1 className='font-bold text-th-xl'>Customers</h1>
                </TabletWidgetLeft>
                <TabletWidgetRight>
                    {/* <Button shape='outline' onClick={filterModal.show}>
                        <FilterIcon/>Filter
                    </Button> */}
                    <CanAccess resource={USERS_CONFIG.name} action='create'>
                        <Button href='/users/create'>Add Customer</Button>
                    </CanAccess>
                </TabletWidgetRight>
            </TabletWidgets>
            <TabletWidgets>
                <TabletWidgetLeft>
                    <TextField
                        control={control} fieldName='search' placeholder="Search by ID, name, email, or phone number"
                        className='w-full' handleEnter={doFilter}
                    />
                </TabletWidgetLeft>
                <TabletWidgetRight>
                    <Pill textColor='#0B5CAD' backgroundColor='#CBE2F9'>{tableQueryResult.data ? tableQueryResult.data.total : 0}</Pill>customers found
                </TabletWidgetRight>
            </TabletWidgets>
            <Table>
                <THead>
                    <Th sorting={{ column: 'id', sorters: sorters, setSorters: setSorters }}>Customer ID</Th>
                    <Th sorting={{ column: 'name', sorters: sorters, setSorters: setSorters }}>Name</Th>
                    <Th>Phone Number</Th>
                    <Th>Address</Th>
                    <Th>Created At</Th>
                    <Th>Available Credits</Th>
                    <Th>Status</Th>
                    <Th>Actions</Th>
                </THead>
                {!tableQueryResult.data ? <td>s</td> : tableQueryResult.data.data.map((user, index) => (
                    <TBody key={index}>
                        <Td>{user.id}</Td>
                        <Td>{user.name}</Td>
                        <Td>{user.phoneNumber}</Td>
                        <Td>{user.defaultAddress ? formatAddressAny(user.defaultAddress) : '--'}</Td>
                        <Td>{formatFullDate(user.createdAt, { showDay: false, showTime: false })}</Td>
                        <Td>$ {user.balance.toFixed(2)}</Td>
                        <Td className='flex items-center'>
                            <Badge color={(user.isActive ? 'success' : 'danger')} className='text-th-xs'>
                                {(user.isActive ? 'Active' : 'Inactive')}
                            </Badge>
                        </Td>
                        <TAction id={`edit-user-${user.id}`}>
                            <TActionItem access={{ resource: USERS_CONFIG.name, action: 'show' }}>
                                <NavLink type='button' className='text-sm' to={`/users/${user.id}`}>
                                    View
                                </NavLink>
                            </TActionItem>
                            <TActionItem access={{ resource: USERS_CONFIG.name, action: 'edit' }}>
                                <NavLink type='button' className='text-sm' to={`/users/edit/${user.id}`}>
                                    Edit
                                </NavLink>
                            </TActionItem>
                            <TActionItem access={{ resource: USERS_CONFIG.name, action: 'edit' }}>
                                <button type='button' className='text-sm' onClick={() => { toggleActiveUser(user.id || 0, !user.isActive) }}>
                                    {user.isActive ? 'Deactivate' : 'Activate'}
                                </button>
                            </TActionItem>
                        </TAction>

                    </TBody>
                ))}
            </Table>
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0}
                    clickHandler={changePage} range={5}
                />
            </div>
        </BaseCard>
        <FilterModal modal={filterModal} setPageSize={setPageSize} filters={filters} setFilters={setFilters} />
    </>)
}

const FilterModal = (
    { modal, setPageSize, setFilters, filters }: {
        modal: useModalReturnType, setPageSize: any, setFilters: any, filters: any
    }
) => {
    return (<></>
        // <Modal heading={'Filter'} close={modal.close} visible={modal.visible}>
        //     <SelectField label='Page Size' value={filters} onChange={(e: any) => {setPageSize(e.target.value)}} options={[
        //         {value: 20}, {value: 50}
        //     ]}/>  
        // </Modal>
    )
}

const formatDefaultAddress = (user: { [key: string]: any }) => {
    const defaultAddress = user.addresses.find((address: any) => (
        address.isDefault === true
    ))
    return (
        defaultAddress ? (
            defaultAddress.buildingNumber + ' ' + defaultAddress.street + ' ' + defaultAddress.postalCode
        ) : ''
    )
}
const getAuthMethods = (user: { [key: string]: any }) => {
    let authMethods: string[] = []
    if (user.googleAuth) { authMethods.push('Google') }
    if (user.appleAuth) { authMethods.push('Apple') }
    if (Boolean(user.phoneNumber)) { authMethods.push('Phone number') }
    if (Boolean(user.password)) { authMethods.push('Password') }
    return authMethods.join(', ')
}