import { useEffect, useRef } from "react"
import { CloseIcon } from "../Svg"

export interface ModalProps {
    visible: boolean
    close: () => void
    children?: JSX.Element | JSX.Element[]
    heading: JSX.Element | string,
    width?: string,
    className?: string
    renderChildOnHidden?: boolean
    hideClose?: boolean
    [key: string]: any
    subheading?: string
}
export default function Modal(
    { visible, close, children, heading, width, className, renderChildOnHidden = false, hideClose = false,subheading, ...rest }: ModalProps
) {
    const ref = useRef<HTMLDivElement | null>(null)
    const timeOutId = useRef<any | null>(null)
    let classes = 'modal' + (className ? ' ' + className : '')

    useEffect(() => {
        if (ref && ref.current) {
            if (visible) {
                ref.current.classList.add('is-visible', 'is-shown')
            }
            else {
                ref.current.classList.remove('is-shown')
                timeOutId.current = setTimeout(() => {
                    ref.current?.classList.remove('is-visible')
                }, 300)
            }
        }
    }, [visible, ref, timeOutId])

    useEffect(() => {
        return () => {
            clearTimeout(timeOutId.current)
        }
    }, [timeOutId])
    return (
        <>
            <section ref={ref} className={classes} {...rest}>
                <div className="content" style={{ width: width || 'auto' }}>
                    <div className="header">
                        <div>
                        <h6 className="heading">{heading}</h6>
                        <p>{subheading ? subheading : ""}</p>
                        </div>
                        {!hideClose &&
                            <button className="close-btn" onClick={close}>
                                <CloseIcon />
                                <span className="sr-only">Close Modal</span>
                            </button>
                        }
                    </div>
                    {(visible || renderChildOnHidden) &&
                        <div className="body">{children}</div>
                    }
                </div>
            </section>
        </>
    )
}